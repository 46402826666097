<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>设置管理</el-breadcrumb-item>
        <el-breadcrumb-item>首页轮播图</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">所属分类：</div>
                <el-select v-model="search.belonging" placeholder="请选择">
                  <el-option :label="item.label" :value="item.value" v-for="(item,index) in belonging"
                             :key="index"></el-option>
                </el-select>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="bannerAdd" @click="goAdd">新增商品类型</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" label="banner图">
              <template slot-scope="scope"><img @click="handleSee(scope.row.imageUrl)" class="icon-img"
                                                :src="scope.row.imageUrl"/></template>
            </el-table-column>
            <el-table-column align="center" label="来源类型">
              <template slot-scope="scope">{{ scope.row.sourceType == 1 ? "商品" : "" }}</template>
            </el-table-column>
            <el-table-column align="center" label="所属分类">
              <template slot-scope="scope">{{ scope.row.belonging | getBelonging }}</template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">{{ scope.row.status | getStatus }}</template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人" min-width="60"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人" min-width="60"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="bannerUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="bannerStatus" @click="changeStatus(scope.row)">
                    {{ scope.row.status === 1 ? '下架' : '上架' }}
                  </div>
                  <div class="border_center_left" v-if="bannerDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="所属分类：" prop="belonging">
          <span v-if="type === 'details'"> {{ upData.belonging | getBelonging }}</span>
          <el-select v-else v-model="upData.belonging" placeholder="请选择所属分类">
            <el-option :label="item.label" :value="item.value" v-for="(item,index) in belonging"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源类型：" prop="sourceType">
          <span v-if="type === 'details'"> {{ upData.sourceType == 1 ? "商品" : "" }}</span>
          <el-select v-else disabled v-model="upData.sourceType" placeholder="请选择来源类型">
            <el-option label="商品" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <span v-if="type === 'details'"> {{ upData.sort }}</span>
          <el-input-number type="number" :step-strictly="true" :controls="false" :step="1" v-model="upData.sort"
                           placeholder="请输入排序"></el-input-number>
        </el-form-item>
        <el-form-item v-if="type !== 'details'" label="选择商品：" prop="sourceId">
          <el-button icon="el-icon-search" size="mini" type="danger" circle @click="handleOpenGood"></el-button>
        </el-form-item>
        <el-form-item v-if="upData.commodityName" label="商品名称：" prop="sourceId">{{ upData.commodityName }}
        </el-form-item>
        <el-form-item label="banner：" prop="imageUrl">
          <div class="flexRow">
            <div class="up-image" v-if="type === 'details'">
              <div class="image-list">
                <img @click="handleSee(upData.imageUrl)" :src="upData.imageUrl" alt/>
              </div>
            </div>
            <div class="up-image" v-else>
              <div v-for="(item, index) in upList" class="image-list" :key="index">
                <img @click="handleSee(item.url)" :src="item.url" alt/>
                <i class="el-icon-error" @click="removeImage(index)"></i>
              </div>
            </div>
            <el-upload
                v-if="type !== 'details'"
                action
                accept=".jpg, .jpeg, .png, .gif"
                :on-exceed="onExceed"
                :on-error="onError"
                :file-list="upList"
                list-type="picture-card"
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
                :before-upload="beforeFileUpload"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>

      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="选择商品"
        :visible.sync="dialogGoods"
        width="70%"
        :before-close="handleGoodsClose">
      <div class="flexRow justifyContentSpac">
        <div class="flexRow goods-box">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">商品名称：</div>
            <el-input v-model="commodityName" placeholder="搜索"></el-input>
          </div>
          <el-button type="primary" @click="searchGoodData" style="margin-left:20px;">搜索</el-button>
          <el-button @click="initGoodSearch" style="margin-left:20px;">重置</el-button>
        </div>

        <div class="tip-title">双击选择商品</div>
      </div>
      <div class="table-content">
        <el-table
            border
            :data="goodsList"
            style="width: 100%"
            @row-dblclick="selectGood"
            :header-cell-style="this.utils.getRowClass"
        >
          <el-table-column align="center" prop="commodityName" label="商品名称" min-width="150"
                           :show-overflow-tooltip="true"></el-table-column>
          <el-table-column align="center" label="商品图片">
            <template slot-scope="scope">
              <img @click="handleSee(scope.row.commodityImgs[0])" class="icon-img" :src="scope.row.commodityImgs[0]"/>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="typeName" label="商品类型"></el-table-column>
        </el-table>
        <div class="flexRow justifyContentSpac" style="margin-top:20px">
          <div></div>
          <el-pagination
              @size-change="handleSizeGoodChange"
              background
              layout="total, prev, pager, next,sizes"
              :total="goodTotal"
              :page-sizes="[5, 10, 15, 20, 30, 50, 100]"
              :page-size="goodPageSize"
              @current-change="changeGoodPage"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    const validatorImageUrl = (rule, value, callback) => {
      if (this.upData.imageUrl === "") {
        callback(new Error("请上传banner图"));
      } else {
        callback();
      }
    }
    const validateSort = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入排序'));
      } else {
        callback();
      }
    };
    const validateSourceId = (rule, value, callback) => {
      if (value === "") {
        callback(new Error('请选择商品'));
      } else {
        callback();
      }
    };
    return {

      search: {
        belonging: null
      },
      type: "",
      tableList: [],
      upList: [],
      imageUrl: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        imageUrl: "",
        commodityName: "",
        belonging: null,
        sourceId: null,
        sourceType: 1,
        status: 0,
        sort: 0,
      },
      rules: {
        belonging: [{required: true, message: "请选择所属分类", trigger: "change"}],
        sourceType: [{required: true, message: "请选择来源类型", trigger: "change"}],
        imageUrl: [{required: true, validator: validatorImageUrl, trigger: "change"}],
        sort: [{required: true, validator: validateSort, trigger: "blur"}],
        sourceId: [{required: true, validator: validateSourceId, trigger: "blur"}]
      },
      belonging: [
        {label: '推荐', value: 1},
        {label: '穿越飞船', value: 2},
        {label: '玩乐达人', value: 3},
      ],
      status: [
        {label: '下架', value: 0},
        {label: '上架', value: 1}
      ],
      bannerAdd: false,
      bannerDel: false,
      bannerUpd: false,
      bannerStatus: false,
      dialogGoods: false,
      total: 0,
      page: 1,
      pageSize: 10,
      goodsList: [],
      goodTotal: 0,
      goodPage: 1,
      goodPageSize: 3,
      commodityName: ''
    };
  },
  filters: {
    getBelonging(value) {
      const data = _this.belonging.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getStatus(value) {
      const data = _this.status.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    // 打开选择商品弹框
    handleOpenGood() {
      let _this = this;
      _this.goodPage = 1;
      _this.dialogGoods = true;
      _this.getGoodsData();
    },
    handleGoodsClose() {
      let _this = this;
      _this.goodPage = 1;
      _this.dialogGoods = false;

    },
    getGoodsData() {
      let _this = this;
      const requestData = {
        page: this.goodPage,
        pageSize: this.goodPageSize,
        search: {
          commodityName: _this.commodityName
        }
      }

      _this.$api
          .getCommodityInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.goodTotal = res.data.data.total;
              _this.goodsList = res.data.data.records;
              _this.goodsList.forEach(item => {
                item.commodityImgs = item.commodityImgs.split(",");
              });
            }
          });
    },
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getBanner(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "banner:add") {
          _this.bannerAdd = true;
        }
        if (v == "banner:status") {
          _this.bannerStatus = true;
        }
        if (v == "banner:del") {
          _this.bannerDel = true;
        }
        if (v == "banner:upd") {
          _this.bannerUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 上下架
    changeStatus(val) {
      const data = JSON.parse(JSON.stringify(val));
      const title = data.status === 1 ? '下架' : '上架';

      let _this = this;
      _this.$confirm(`此操作将${title}该数据, 是否继续?`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.statusBanner({id: val.id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success(`${title}成功`);
                  _this.getData();
                } else {
                  _this.$message.error(`${title}失败`);
                }
              }
            });
          })
          .catch(() => {
          });

    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addBanner(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editBanner(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        imageUrl: "",
        commodityName: "",
        belonging: null,
        sourceId: null,
        sourceType: 1,
        status: 0,
        sort: 0,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        belonging: null
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    // 商品
    searchGoodData() {
      let _this = this;
      _this.goodPage = 1;
      _this.getGoodsData();
    },
    initGoodSearch() {
      let _this = this;
      _this.commodityName = "";
      _this.goodPage = 1;
      _this.getGoodsData();
    },
    handleSizeGoodChange(e) {
      let _this = this;
      _this.goodPage = 1;
      _this.goodPageSize = e;
      _this.getGoodsData();
    },
    changeGoodPage(e) {
      let _this = this;
      _this.goodPage = e;
      _this.getGoodsData();
    },
    // 选择商品
    selectGood(row) {
      let _this = this;
      _this.upData.sourceId = row.id;
      _this.upData.commodityName = row.commodityName;
      _this.handleGoodsClose();
    },
    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        imageUrl: "",
        commodityName: "",
        belonging: null,
        sourceId: null,
        sourceType: 1,
        status: 0,
        sort: 0,
      }
      _this.upList = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.imageUrl
      });
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.imageUrl
      });
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delBanner({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
            _this.$message.error('删除失败');
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.imageUrl = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.imageUrl = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.imageUrl = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}

.goods-box {
  margin-bottom: 20px;

  .search-title {
    width: 100px;
  }
}
</style>
